// Admin.js
import React from 'react';
import styled from 'styled-components';

const SidebarContainer = styled.div`
  min-width: 90vw;
  height: 100vh;
  background-color: #333;
  color: white;
  display: flex;
  flex-direction: row; /* Changed from column to row */
`;

const SidebarLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 1rem;
`;

const SidebarLink = styled.a`
  text-decoration: none;
  color: white;
  //margin-bottom: 10px;
  cursor: pointer;
  margin-right: 2rem;
  padding: 1rem;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
    background-color: white;
    bottom: 5px;
  }
`;

const RightSection = styled.div`
  flex: 1; /* Take remaining space */
  background-color: #555; /* Change the background color as needed */
  padding: 1rem;
  text-align: center;
  color: white;
`;

const Admin = () => {
  return (
    <SidebarContainer>
      {/* Sidebar Links */}
      <SidebarLinksContainer>
        <SidebarLink href="/">Logo</SidebarLink>
        <SidebarLink href="/">Home</SidebarLink>
        <SidebarLink href="/about">About Us</SidebarLink>
        <SidebarLink href="/">Principal Page</SidebarLink>
        <SidebarLink href="/">Director Page</SidebarLink>
        <SidebarLink href="/">Gallery Section</SidebarLink>
        <SidebarLink href="/">Parent Testinomial</SidebarLink>
        <SidebarLink href="/blog">Blog</SidebarLink>
        {/* Add other sidebar links as needed */}
      </SidebarLinksContainer>

      {/* Right Section */}
      <RightSection>
        {/* Add your content for the right section here */}
        <a>ADMIN PANEL</a>
      </RightSection>
    </SidebarContainer>
  );
}

export default Admin;
