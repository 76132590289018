import React from "react";
import Admin from "../Components/AdminPageComponent/Admin";
import AdminDirect from "../Components/AdminPageComponent/AdminBlog";
import AdminPrince from "../Components/AdminPageComponent/AdminPrince";
import Section from "../Components/AdminPageComponent/Section";
import AboutUsSection from "../Components/AdminPageComponent/AboutUsSection";

function AdminPage() {
  return (
    <>
        {/* <Admin />
        <Section
          OuterBackgroundColor={"moccasin"}
          sectionName={"About Us"}
          innerBackgroundColor={"lightblue"}
          title={"About Us"}
        >
          <AboutUsSection />
        </Section>
        <Section
          OuterBackgroundColor={"lightgrey"}
          sectionName={"Principal Page"}
          innerBackgroundColor={"green"}
          title={"About Us"}
        >
          <AboutUsSection />
        </Section>
        <Section
          OuterBackgroundColor={"moccasin"}
          sectionName={"Director page"}
          innerBackgroundColor={"lightblue"}
          title={"About Us"}
        >
          <AboutUsSection />
        </Section>
        <Section
          OuterBackgroundColor={"lightgreen"}
          sectionName={"Admission page"}
          innerBackgroundColor={"lightgray"}
          title={"About Us"}
        >
        <AboutUsSection />
      </Section> */}
      {/* <AdminPrince />
      <AdminDirect /> */}
      {/* <AdminBlog />
        <AdminNotice /> */}
    </>
  );
}

export default AdminPage;
