import React, { useState } from "react";
import styled from "styled-components";
import { Input } from "../WebSitePageComponent/ContectUs";

export default function AboutUsSection() {
  const [aboutItems, setAboutItems] = useState([]);
  const [textInput, setTextInput] = useState("");
  const [imageLink, setImageLink] = useState("");
  const [editIndex, setEditIndex] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleAddItem = () => {
    if (textInput.trim() === "") {
      alert("Text is required.");
      return;
    }

    if (editIndex !== null) {
      const updatedAboutItems = [...aboutItems];
      updatedAboutItems[editIndex] = {
        text: textInput,
        image: imageLink || getImageUrl(selectedFile),
      };
      setAboutItems(updatedAboutItems);
      setEditIndex(null);
    } else {
      setAboutItems([
        ...aboutItems,
        { text: textInput, image: imageLink || getImageUrl(selectedFile) },
      ]);
    }

    setTextInput("");
    setImageLink("");
    setSelectedFile(null);
  };

  const handleEditItem = (index) => {
    setEditIndex(index);
    setTextInput(aboutItems[index].text);
    setImageLink(aboutItems[index].image);
  };

  const handleDeleteItem = (index) => {
    const updatedAboutItems = [...aboutItems];
    updatedAboutItems.splice(index, 1);
    setAboutItems(updatedAboutItems);
    setEditIndex(null);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const getImageUrl = (file) => {
    if (!file) return "";
    return uploadImage(file).then((imageUrl) => imageUrl);
  };

  const uploadImage = (file) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        const imageUrl = `https://example.com/uploads/${file.name}`;
        resolve(imageUrl);
      }, 1000);
    });
  };

  return (
    <>
      <FormContainer>
        <TextField>
          {/* <h2>{isEditOn !== null ? `Edit ${title}` : `Add ${title}`}</h2> */}
        </TextField>
        <label>
          Text :-<br></br>
          {/* <textarea
              value={textInput}
              onChange={(e) => setTextInput(e.target.value)}
            rows={2} */}
          <TextArea
            value={textInput}
            onChange={(e) => setTextInput(e.target.value)}
            rows={2}
          ></TextArea>
          
        </label>
        <br />
        <label>
          Image Link :-<br></br>
          {/* <input
            type="text"
              value={imageLink}
              onChange={(e) => setImageLink(e.target.value)}
          /> */}

          <Input  
          value={imageLink}
          onChange={(e) => setImageLink(e.target.value)}/>
        </label>
        <br />
        <label>
          Upload Image :- <br></br>
          <input type="file" accept="image/*" onChange={handleFileChange} />
        </label>
        <br />
        <StyledButton primary onClick={handleAddItem}>
          {editIndex !== null ? "Save" : "Add"}
        </StyledButton>
      </FormContainer>
      <AboutList>
        <TextField>{/* <h2>{`${title} List`}</h2> */}</TextField>
        {aboutItems.map((item, index) => (
          <AboutItem key={index}>
            <AboutItemText>
              <textarea value={item.text} readOnly rows={4} />
            </AboutItemText>
            <div>
              <img
                src={item.image}
                alt={item.text}
                style={{ maxWidth: "50vw", maxHeight: "150px" }}
              />
            </div>
            <div>
              <StyledButton onClick={() => handleEditItem(index)}>
                Edit
              </StyledButton>
              <StyledButton onClick={() => handleDeleteItem(index)}>
                Delete
              </StyledButton>
            </div>
          </AboutItem>
        ))}
      </AboutList>
    </>
  );
}

const AboutItem = styled.li`
  margin-bottom: 1rem;
  display: flex;
  min-width: 10vw;
  min-height: 20vh;
  flex-direction: column;
  flex-wrap: wrap;
  border: 1px solid #ddd;
  padding: 1rem;
  background-color: #fff;
`;

const AboutItemText = styled.div`
  strong {
    display: block;
    margin-bottom: 0.5rem;
  }
  textarea {
    min-width: 40vw;
    min-height: 20vh;
    resize: horizontal;
    margin-top: 0.5rem;
  }
`;

const StyledButton = styled.button`
  background-color: ${(props) => (props.primary ? "#3498db" : "#e74c3c")};
  color: #fff;
  padding: 0.5rem 1rem;
  border: none;
  max-width: 30vw;
  margin-right: 1rem;
  cursor: pointer;
  margin-bottom: 1rem;
`;

const HalfContainer = styled.div`
  flex: 1;
  margin-right: ${(props) => (props.rightMargin ? "2rem" : "1rem")};
`;

const FormContainer = styled(HalfContainer)`
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  border: 5px solid #ddd;
  margin-right: 0px;
`;
const TextField = styled.div`
  text-align: center;
  margin-top: 0px;
  margin-bottom: 0.5rem;
`;

const AboutList = styled.ul`
  list-style: none;
  min-width: 50vw;
  min-height: 60vh;
  padding: 1.5rem;
  overflow-y: auto;
  border: 5px solid #ddd;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
`;
const TextArea = styled.textarea`
  background-color: rgb(247 247 247 /1);
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  resize: none;
  margin-top: 1rem;
`;


