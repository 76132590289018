import React from "react";
import WebSite from "./pages/WebSite.jsx";
import { ToastContainer } from "react-toastify";
import { createGlobalStyle } from "styled-components";
import "react-toastify/dist/ReactToastify.css";
import AdminPage from "./pages/AdminPage.jsx";

const App = () => {
  return (
    <>
      <GlobalStyle />
      <ToastContainer />
      <AdminPage />
      <WebSite />
    </>
  );
};

export default App;

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
`;
