import styled from "styled-components";
import { COLOR } from "../../Utility/Colors";

export const OuterComponent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Times New Roman", Times, serif;
  align-items: center;
`;

export const Container = styled.div`
  width: 100%;
  padding: 2rem;
  min-height: 80vh;
  display: flex;
  font-family: "Times New Roman", Times, serif;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: "Times New Roman", Times, serif;
  align-items: center;
  background-color: ${COLOR.primary};
  width: 100%;
  padding: 0.5rem 3rem;
  text-align: center;
  gap: 0.5rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const InnerContainer = styled.div`
  width: 100%;
  font-family: "Times New Roman", Times, serif;
  height: 100%;
`;

export const StudentImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  flex-shrink: 0;
  transition: all .3s ease;
  &:hover{
    scale: 1.1;
  }
`;