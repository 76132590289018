export const options = [
    "Home",
    "About School",
    // "Academic",
    "Achievements",
    "Activities",
    "Facilities",
    "Gallery",
    // "Career",
    "Contact Us",
    "Results",
  ];

export const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  export const lottieAnimations =[
    'https://lottie.host/e824b98d-90e5-438c-91eb-3057d4f470e6/B8HZ1W7GNo.json',
    'https://lottie.host/2b690955-f88a-4b94-8ecb-5bb9de7dfc5c/fQO75rfiTN.json',
    'https://lottie.host/3438320b-9e79-4441-af15-15d6dea6c2a5/QAuJiUA65s.json',
    'https://lottie.host/b696c3bb-099f-498d-b0c4-6c5bfc649343/f4zS40FcOd.json',
    'https://lottie.host/fac63332-8c7f-432d-ac6a-9bf9361cb712/z9r5ZC9pPi.json',
    'https://lottie.host/95937e39-5ef0-474e-b4f9-177534707310/X4oYY4JRr7.json',
    'https://lottie.host/827bed6a-013b-46a4-ba23-2a7c453645e4/ASraSkZsrw.json',
    'https://lottie.host/4cc11a9f-899d-44c2-841e-5cabcfb50a5c/f8RFRUBody.json',
    'https://lottie.host/882812ad-d5d1-4bf1-944a-b4e173c514bd/tuO2i0tScv.json',
    'https://lottie.host/7556e172-66ea-4a77-bd86-25d82850e344/u7FgfmWDH3.json',
    'https://lottie.host/e2d17b86-0c91-48e8-901e-b7e31f272791/25dQxLbYfi.json',
    'https://lottie.host/04010eb3-1afd-4c87-8d6f-8de3449ccf01/o9G8hnKZxh.json'
  ]