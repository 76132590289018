import React, { useState } from "react";
import styled from "styled-components";
import { Container } from "../CommonStyledComponent/CommonStyledComponent";
import { COLOR } from "../../Utility/Colors";
import SectionName from "../CommonComponent/SectionName";

const Section = ({
  OuterBackgroundColor,
  innerBackgroundColor,
  sectionName,
  children,
}) => {


  return (
    <Container style={{ backgroundColor: OuterBackgroundColor }}>
      <SectionName>{sectionName}</SectionName>
      <PageeContainer>
        <Containernew innerBackgroundColor={innerBackgroundColor}>
          {children}
        </Containernew>
      </PageeContainer>
    </Container>
  );
};

export default Section;

const PageeContainer = styled.div`
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  padding: 2rem;
  background-color: ${COLOR.whitesmoke};
  gap: 2rem;
  margin: 2rem 0;
  min-height: 70vh;
  width: 90%;

  border-radius: 1rem;
  @media (min-width: 768px) {
    flex-direction: ${({ isOpposite }) => (isOpposite ? "row" : "row-reverse")};
    padding: 1rem;
  }
`;
const Containernew = styled.div`
  display: flex;
  border-radius: 1rem;
  width: 100%;
  background-color: ${({ innerBackgroundColor }) => innerBackgroundColor};
  justify-content: space-between;

  /* margin: 2rem; */

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
